import dev from "./dev";
import prod from "./prod";

import uat from "./uat";

// 是否开发模式
export const isDev = import.meta.env.MODE === "development";
export const isUat = import.meta.env.MODE === "uat";
// 配置
export const config = {
	// 项目信息
	app: {
		name: "修模管理平台",
		title_name: "速哇3D摄影修模管理平台",
		// 菜单
		menu: {
			// 是否分组显示
			isGroup: false,
			// 自定义菜单列表
			list: []
		},

		// 路由
		router: {
			// 模式
			mode: "hash",
			// 转场动画    
			transition: "slide",
			// 首页组件
			home: () => import("/$/missionCenter/views/myRepairOrder.vue")
		},

		// 字体图标库
		iconfont: []
	},

	// 忽略规则
	ignore: {
		// 不显示请求进度条
		NProgress: ["/sys/info/record", "/client/uuid", "/takephoto/syncCameraStatus"],
		// 页面不需要登录验证
		token: ["/login", "/401", "/403", "/404", "/500", "/502"]
	},

	// 调试
	test: {
		token: "",
		mock: false,
		eps: false
	},

	// 当前环境
	...(isDev ? dev : isUat ? uat : prod)
};

export * from "./proxy";
