import { proxy } from "./proxy";

export default {
	host: proxy["/prod"].target,
	// 请求地址
	baseUrl: "https://repair.api.suwa3d.com/admin/",
	oss: {
		website: "https://www.suwa3d.com",
		"3dview": "https://3dview.suwa3d.com",
		webwechat: "https://www.suwa3d.com",
		securedata: "https://wechat.suwa3d.com"
	},
	msgWebsocket: {
		WEBSOCKETADDRESS: "wss://repair.api.suwa3d.com"
	},
	weightWs: "ws://localhost:10999/ws"
};