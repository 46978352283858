// export const proxy = {
// 	"/dev": {
// 		target: "http://127.0.0.1:8001",
// 		changeOrigin: true,
// 		rewrite: (path: string) => path.replace(/^\/dev/, "")
// 	},

// 	"/prod": {
// 		target: "https://show.cool-admin.com",
// 		changeOrigin: true,
// 		rewrite: (path: string) => path.replace(/^\/prod/, "/api")
// 	}
// };
export const proxy = {
    "/shop": {
        target: "http://localhost:12880",
        changeOrigin: true,
        rewrite: (path: string) => path.replace(/^\/dev/, "admin/")
    },
    "/dev": {
        target: "http://localhost:12880",
        changeOrigin: true,
        rewrite: (path: string) => path.replace(/^\/dev/, "admin/")
    },

    "/prod": {
        target: "https://repair.api.suwa3d.com/admin", // "http://127.0.0.1:10001",
        changeOrigin: true,
        rewrite: (path: string) => path.replace(/^\/pro/, "/admin")
    },

    "/uat": {
        target: "http://192.168.2.10:12880", // "http://127.0.0.1:10001",
        changeOrigin: true,
        rewrite: (path: string) => path.replace(/^\/uat/, "/admin")
    }
};
